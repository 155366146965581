import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Button from './ui/buttons/Button'
import ButtonClose from './ui/buttons/ButtonClose'

import SearchSanity from './ui/SearchSanity'
import Social from './ui/Social'
import clsx from 'clsx'
import { publish, subscribe, unsubscribe } from 'pubsub-js'
import SocialWidget from './ui/SocialWidget'
import ButtonInternalLink from './ui/buttons/ButtonInternalLink'
import Menu from './Menu'
import { _localizeText, _linkResolver, _localizeField } from '../core/utils'
import ButtonHome from './ui/buttons/ButtonHome'

type Props = {
  topNavLink: Queries.SanityLink
  navMenu: Queries.SanityDownloadOrExternalLinkOrInternalLinkOrInternalLinkDirectory[]
  navMenuSecondary: Queries.SanityExternalLinkOrInternalLink[]
}
const HeaderNav = ({ topNavLink, navMenu, navMenuSecondary }: Props) => {
  const [active, setActive] = useState(false)
  const [searchActive, setSearchActive] = useState(true)
  const [isHome, setIsHome] = useState<boolean>(false)

  useEffect(() => {
    // console.log(location.pathname)
    setIsHome(location.pathname === '/')
    const tokenRoute = subscribe('ROUTE_UPDATE', (e, d) => {
      // console.log(d)
      setActive(false)
      setIsHome(d.pathname === '/')
    })

    return () => {
      unsubscribe(tokenRoute)
    }
  }, [])

  useEffect(() => {
    publish('IS_MODAL', active)
  }, [active])

  const _toggle = () => {
    // console.log('_toggle', active)
    setActive(!active)
  }

  return (
    <header className="sticky top-0 z-50">
      <nav
        id="nav-top"
        className={clsx(
          'p-1 pb-0 z-10 absolute md:w-1/2 overflow-x-auto md:overflow-visible hide-sb w-full',
          active ? 'hidden' : ''
        )}
      >
        <ul className="flex menu gap-05">
          <li className="flex site-name">
            <ButtonHome />
          </li>
          <li className={''}>
            <Button size="regular" hoverable={true} onClick={_toggle}>
              {_localizeText('menu')}
            </Button>
          </li>
          {(topNavLink?.internalLink || topNavLink?.externalLink) && (
            <li>
              {topNavLink?.internalLink && (
                <ButtonInternalLink
                  size="regular"
                  path={_linkResolver(topNavLink.internalLink.link)}
                  hoverable={true}
                  btnStyle={{
                    background: 'var(--color-black)',
                    padding: '0',
                  }}
                >
                  {_localizeField(topNavLink.internalLink.label)}
                </ButtonInternalLink>
              )}
              {topNavLink?.externalLink && topNavLink.externalLink.link && (
                <a
                  href={topNavLink.externalLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--regular block-"
                >
                  {_localizeField(topNavLink.externalLink.label)}
                </a>
              )}
            </li>
          )}
          <li className={''}>
            <Button
              size="regular"
              hoverable={true}
              onClick={() => {
                setActive(true)
                // setSearchActive(prev => !prev)
                setSearchActive(true)
              }}
            >
              <span className="icon-search"></span>
            </Button>
          </li>

          <li className="hidden-sm">
            <SocialWidget />
          </li>

          {isHome && navMenuSecondary && (
            <Menu input={navMenuSecondary} label={''} size="regular" />
          )}
        </ul>
      </nav>
      {active && (
        <div
          id="nav-overlay"
          className="fixed inset-0 h-screen overflow-y-auto bg-orange p-1 px-1 pb-6"
        >
          <ul className="header flex gap-05">
            <li className="">
              <ButtonClose setActive={setActive} />
            </li>
            <li className="site-name">
              <ButtonHome />
            </li>
          </ul>
          <div className="inner">
            {searchActive && (
              <div className="nav-overlay--section mt-3">
                <SearchSanity />
              </div>
            )}
            <div className="nav-overlay--section mt-3">
              <Menu input={navMenu} label={_localizeText('navigation')} />
            </div>

            {isHome && navMenuSecondary && navMenuSecondary.length > 0 && (
              <div className="nav-overlay--section mt-3">
                <Menu
                  input={navMenuSecondary}
                  label={_localizeText('language')}
                />
              </div>
            )}
            {/* <div className="nav-overlay--section mt-3">
              <LocaleSwitcher buttonSize="big" />
            </div> */}
            <div className="nav-overlay--section mt-3">
              <Social />
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

export default HeaderNav
